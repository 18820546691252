import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'

const FORM_SELECTOR = '.m-stock-ticker__form'
const SEARCH_TEXTFIELD = '.m-stock-ticker__input'

export default (() => {
  class Basic {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }
      this.currentFocus
      this.form = this.elem.querySelector(FORM_SELECTOR)
      this.searchTextFieldWrapper = this.form.firstChild
      this.searchTextField = this.elem.querySelector(SEARCH_TEXTFIELD)
      this.suggestionDropdown()
      this.init()
    }

    init() {
      if (this.form) {
        this.form.addEventListener('submit', e => {
          this.submitHandler(e)
        })
      }

      // add form submit event on button
      this.elem
        .querySelector('.m-stock-ticker__submit')
        .addEventListener('click', this.submitFormOnSearchBtn.bind(this))
      /* execute a function when someone clicks in the document: */
      document.addEventListener('click', e => {
        this.closeAllLists(e.target)
      })
    }

    submitFormOnSearchBtn(e) {
      // const rapid = { ...ylkStringToObj(btn.dataset.ylk) }
      // this.emitRapidBeacon(rapid.slk, rapid.elmt, rapid)
      this.form.submit()
    }

    async submitHandler(e) {
      e.preventDefault()
      e.stopPropagation()
      const { searchTextField, emitRapidBeacon } = this
      const inputValue = searchTextField.value
      const { quotes } = await this.getSuggestionOptionData(inputValue)
      const form = e.target
      if (!this.checkInputValue(quotes, searchTextField.value)) {
        this.closeAllLists()
        form.reset()
      } else {
        const rapid = { ...ylkStringToObj(form.dataset.ylk) }
        emitRapidBeacon(rapid.slk, rapid.elmt, rapid)
        this.redirect()
      }
    }

    emitRapidBeacon(slk, elmt, obj) {
      const { itc, elm } = obj
      rapidReady(rapid => {
        const rapidPos = 1
        rapid.beaconClick(
          'ticker-widget',
          slk,
          rapidPos,
          {
            itc,
            elmt,
            elm
          },
          'Interaction'
        )
      })
    }

    // check if input value matches suggested option value
    checkInputValue(suggestion, inputText) {
      return suggestion.find(o => o.symbol.toLowerCase() === inputText.toLowerCase()) || false
    }

    suggestionDropdown() {
      this.keyStrokeEvent()
      this.suggestionDropdownLayout() // suggestion dropdown html layout
      this.dropDownSelectionEvent() // add event for up and down and enter
    }

    keyStrokeEvent() {
      this.searchTextField.addEventListener(
        'input',
        e => {
          const rapid = { ...ylkStringToObj(e.target.dataset.ylk) }
          this.emitRapidBeacon(rapid.slk, rapid.elmt, rapid)
        },
        { once: true }
      )
    }

    debounce(func, delay) {
      let debounceTimer
      return function() {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func(), delay)
      }
    }

    // suggestion dropdown layout
    suggestionDropdownLayout() {
      const { searchTextField } = this
      searchTextField.addEventListener(
        'input',
        this.debounce(async e => {
          const val = searchTextField.value
          if (!val) {
            this.closeAllLists()
            return false
          }
          const { quotes } = await this.getSuggestionOptionData(val)
          this.currentFocus = -1

          /* create a DIV element that will contain all the suggestion */
          this.closeAllLists()
          if (!quotes.length) {
            return false
          }
          this.inputClassToggle(searchTextField, 'open')
          /* append the DIV element as a child of the autocomplete container: */
          searchTextField.parentNode.appendChild(this.createSuggestionBox(quotes, val))
          return true
        }, 300)
      )
    }

    // input selection class toggle
    inputClassToggle(searchTextField, action) {
      if (action === 'open') {
        searchTextField.classList.add('m-stock-ticker__input-selected')
        this.searchTextFieldWrapper?.classList.add('pos-inital')
        searchTextField.classList.remove('m-stock-ticker__input')
      } else {
        searchTextField.classList.remove('m-stock-ticker__input-selected')
        this.searchTextFieldWrapper?.classList.remove('pos-inital')
        searchTextField.classList.add('m-stock-ticker__input')
      }
    }

    // getting all suggestion option data
    async getSuggestionOptionData(inputValue) {
      try {
        const jsonResponse = await fetch(
          `/nex/modules/stock_ticker/v1/?isXHR=true&m_mode=json&symbol=${encodeURIComponent(
            inputValue
          )}`
        )
        const res = await jsonResponse.json()
        return res.data.response
      } catch (error) {
        return error
      }
    }

    // creation of suggestion box layout
    createSuggestionBox(quotes, val) {
      const suggestionBox = document.createElement('div')
      suggestionBox.setAttribute('id', 'autocomplete-list')
      suggestionBox.setAttribute('class', 'autocomplete-items')

      const suggestionUl = this.createHtmlELement('UL', { class: 'autocomplete-option-UL' })
      // SYMBOL
      const title = this.createHtmlELement('STRONG', { class: 'symbol' })
      title.innerText = 'Symbols'
      suggestionUl.appendChild(title)
      quotes.forEach(element => {
        /* check if the item starts with the same letters as the text field value: */
        if (element.symbol.substr(0, val.length).toUpperCase() === val.toUpperCase()) {
          /* create a DIV element for each matching element: */
          const suggestionValues = this.createSuggestionOption(element)
          suggestionUl.appendChild(suggestionValues)
        }
      })
      suggestionBox.appendChild(suggestionUl)
      return suggestionBox
    }

    // creation of individual option layout
    createSuggestionOption(element) {
      // div (option)
      const suggestionValues = this.createHtmlELement('LI', {
        class: 'autocomplete-option-value',
        tabindex: 0
      })
      suggestionValues.setAttribute('data-value', element.symbol)
      // left div
      const optionLeftPart = this.createHtmlELement('DIV', { class: 'option-left' })

      if (element.symbol) {
        const leftValueFirst = this.createHtmlELement('P', { class: 'option-left__symbol' })
        leftValueFirst.innerText = `${element.symbol}`
        optionLeftPart.append(leftValueFirst)
      }

      if (element.shortname) {
        const leftValueSecond = this.createHtmlELement('P', { class: 'option-left__shortname' })
        leftValueSecond.innerText = `${element.shortname}`
        optionLeftPart.append(leftValueSecond)
      }

      // right div
      const optionRightPart = this.createHtmlELement('DIV', { class: 'option-right' })
      const rightValue = this.createHtmlELement('P', { class: 'option-right__exchange' })
      rightValue.innerText = `${element.typeDisp}-${element.exchange}`
      optionRightPart.append(rightValue)

      suggestionValues.append(optionLeftPart)
      suggestionValues.append(optionRightPart)

      /* execute a function when someone clicks on the item value */
      suggestionValues.addEventListener('click', e => {
        const inputField = e.currentTarget
        this.searchTextField.value = inputField.dataset.value
        const rapid = { ...ylkStringToObj(this.form.dataset.ylk) }
        this.emitRapidBeacon(rapid.slk, 'srch-asst', rapid)
        this.redirect()
      })
      return suggestionValues
    }

    // html tag creation
    createHtmlELement(element, attribute = null) {
      const htmlTag = document.createElement(element)
      if (attribute) {
        Object.keys(attribute).forEach(property => {
          htmlTag.setAttribute(property, attribute[property])
        })
      }
      return htmlTag
    }

    // redirection
    redirect() {
      const { value } = this.searchTextField
      const actionUrl = this.form.action
      const reqUrl = `${actionUrl}${value}?p=${value}`
      const w = window.open()
      w.opener = null
      w.document.location.replace(reqUrl)
    }

    dropDownSelectionEvent() {
      const { form } = this
      form.addEventListener('keydown', e => {
        let suggestionBox = document.getElementById('autocomplete-list')
        if (suggestionBox) {
          suggestionBox = suggestionBox.getElementsByClassName('autocomplete-option-value')
        }

        if (e.key === 'ArrowDown') {
          this.currentFocus += 1
          this.addActive(suggestionBox)
          return
        }

        if (e.key === 'ArrowUp') {
          this.currentFocus -= 1
          this.addActive(suggestionBox)
          return
        }

        if (e.shiftKey && e.key === 'Tab') {
          this.currentFocus -= 1
          this.changeFocus(suggestionBox)
          return
        }

        if (e.key === 'Tab') {
          this.currentFocus += 1
          this.changeFocus(suggestionBox)
          return
        }

        if (e.key === 'Enter') {
          if (this.currentFocus > -1) {
            if (suggestionBox) {
              suggestionBox[this.currentFocus].click()
              e.preventDefault()
            }
          }
        }
      })
    }

    // for tracking focused element in suggestion list
    changeFocus(node) {
      if (this.currentFocus >= node.length) {
        this.currentFocus = 0
      }

      if (this.currentFocus < 0) {
        this.currentFocus = node.length - 1
      }
    }

    addActive(x) {
      if (!x) {
        return false
      }
      this.changeFocus(x)
      x[this.currentFocus].focus()
      return true
    }

    removeActive(x) {
      Object.values(x).forEach(element => {
        element.classList.remove('autocomplete-active')
      })
    }

    closeAllLists(elmnt) {
      const { searchTextField } = this
      const suggestionValue = document.getElementsByClassName('autocomplete-items')

      Object.values(suggestionValue).forEach(optionElement => {
        if (elmnt !== optionElement && elmnt !== searchTextField) {
          document.querySelector('#autocomplete-list').remove()
          this.inputClassToggle(searchTextField, 'close')
        }
      })
    }
  }
  return Basic
})()
